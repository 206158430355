import FormularioRegistro from "./FormularioRegistro";
import imgPromos from '../assets/images/texto.svg'
import imgBan from '../assets/images/furgoneta.webp'
import { useEffect, useRef, useState } from "react";
import ResizeObserver from 'resize-observer-polyfill';

export default function SeccionFormulario() {

    const [ alturaBan, setAlturaBan ] = useState(0);
    const [ topBan, settopBan ] = useState(400);
    const sectionDr = useRef(null)
    const letrasInit = useRef(null)

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setAlturaBan(entry.contentRect.height - (letrasInit.current.offsetHeight)*.9);
                settopBan(letrasInit.current.offsetHeight)
            }
        })
        const currentSectionDr = sectionDr.current;
        if (currentSectionDr) {
            resizeObserver.observe(currentSectionDr);
        }
        
        return () => {
            if (currentSectionDr) {
                resizeObserver.unobserve(currentSectionDr);
            }
        };
    }, [])

    return <div className="cont-seccion-formulario">
        <div className="columna-completa cont-formulario-s">
            <div className="section-form-iz">
                <img ref={ letrasInit } src={ imgPromos } alt="Pamper your furry-friend with us!, Enjoy a great look, safe environment and happy tail!, Try us out!, Get 10% OFF on First Grooming!, BOOK TODAY and also get a FREE Teeth Brushing or Flea Dip!" />
                {/*<video className="cont-video-tlc" autoPlay controls muted poster={ preview }>
                    <source src={ 'https://mobilepetgroomingorlando.com/large-files-host/Version-Final-TLC.mp4' } type="video/mp4" />
                </video>*/}
                {/*<p className="text-our-experienced">Our experienced, professional and loving pet groomers will leave your <span style={{ whiteSpace: 'nowrap' }}>fur-baby</span> <strong>looking his best!</strong></p>**/}
                <img className="img-ban" src={ imgBan } alt='TLC Grooming' style={{ height: alturaBan, top: topBan }}/>
            </div>
            <div className="section-form-dr" ref={ sectionDr }>
                <FormularioRegistro />
            </div>
        </div>
        <div className="form-background">
            <div className="rosado"></div>
            <div className="blanco"></div>
        </div>
    </div>
}